@use "@/styles/variables" as *;

@forward "./styles.base.module.scss";

.inset {
  &[data-direction="left"] {
    @media (min-width: $breakpoint-lg) {
      left: 30px;
    }
  }

  &[data-direction="right"] {
    @media (min-width: $breakpoint-lg) {
      right: 30px;
    }
  }
}
