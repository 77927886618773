@use '@/styles/variables' as *;

// .inset must always have a higher precedence than .wrapper
@layer wrapper {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    &[data-position="top"],
    &[data-position="left"] {
      order: -1;
    }

    &[data-position="right"],
    &[data-position="bottom"] {
      order: 1;
    }
  }
}

.inset {
  position: absolute;
  z-index: 1;
  padding: 10px;

  &[data-position="top"] {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &[data-position="bottom"] {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &[data-position="left"] {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &[data-position="right"] {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.horizontal {
  flex-direction: row;
}

.vertical {
  flex-direction: column;
}

.button {
  background: none;
  appearance: none;
  border: none;
  border-radius: 50%;
  padding: 0;
  margin: 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: opacity 300ms;
}

/* <button> that has an <img> inside */
.hasThumbnail {
  border-radius: 8px;
  overflow: hidden;
  height: auto;
  width: 80px;
  position: relative;
}

.thumbnail {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.active {
  opacity: 1;
}

.inactive {
  opacity: 0.5;
}
