@use "@/styles/variables" as *;

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  &[data-direction="vertical"] {
    flex-direction: column;
  }

  // When the slider is disabled, we want to disable all pointer interactions. Typically
  // this is when the user has zoomed in on the slider and we don't want to allow them to
  // interact with the slider.
  &.interactionDisabled {
    pointer-events: none;
  }
}

.navigationWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;

  &[data-direction="vertical"] {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
}

.swiper {
  width: 100%;
}
