@use '@/styles/variables' as *;

.button {
  background: none;
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  margin: 4px;
  transition: opacity 300ms;
  color: $color-black;
  font-size: 30px;

  overflow-y: visible;

  &[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.inset {
  position: absolute;
  z-index: 2;

  &[data-direction="up"] {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &[data-direction="down"] {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &[data-direction="left"] {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &[data-direction="right"] {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}