@use "@/styles/variables" as *;

@forward "./styles.base.module.scss";

.wrapper {
  &[data-direction="vertical"] {
    @media (max-width: $breakpoint-lg) {
      align-items: flex-start;
    }
  }

  @media (max-width: $breakpoint-lg) {
    background-color: $color-secondary-100;
  }
}
