@use "@/styles/variables" as *;

@forward "./styles.base.module.scss";

@layer wrapper {
  .wrapper {
    @media (max-width: $breakpoint-lg) {
      padding: 10px $space-lg;
    }
  }
}

.button {
  border-radius: 0;
}

/* <button> that has an <img> inside */
.hasThumbnail {
  border-radius: 0;
}
